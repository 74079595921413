$(document).foundation();

$(document).ready(function () {

  /**
  * Functions on page load
  */

  initJs();

  /**
   * Functions on window resize
   */

  $(window).resize($.debounce(500, function () {
    reInitJs();
  }));

  /**
   * Functions on scrolling
   */

  $(window).scroll(function () {
    //headerControle();
    //hideLayer();
  });

});


/** 
 * Initialisierung (on load)
 * Re-Initalisierung (on resize)
 */

var w = false;
var breakpoint = 600;

function initJs() {
  resizeFn();      // resize Funktionen on page load
  toggleNav();
  wg_moveUp();     // Navigation ein- und ausblenden
}


function reInitJs() {
  var wNew = $(window).width();
  if ((wNew >= breakpoint && w < breakpoint) || (wNew < breakpoint && w >= breakpoint)) { resizeFn(); }
  /* debug
  if(wNew >= breakpoint && w < breakpoint) { alert("change to large device: "+w+"-"+wNew); w = wNew; return true; }
  if(wNew < breakpoint && w >= breakpoint) { alert("change to small device: "+w+"-"+wNew); w = wNew; return true; }
  alert("no change: "+w+"-"+wNew); w = wNew; return false;
  */
}


function resizeFn() {
  w = $(window).width();

  if (w >= breakpoint) {
    //alert("large device: "+w);

/* Toggle Logo */

  } else {
    //alert("small device: "+w);
  }
}


/** 
 * Functions
 */

/* Toggle Referenzen Layer  */

function toggleNav() {
  $("#nav-trigger").click(function () {
    var trigger = $(this);
    $('#mainnav').slideToggle(500, function () {
      $(trigger).toggleClass('open')
    });
  });

  $('.menu li').on('click', function () {
    $('#mainnav').slideToggle(500, function () {
      $('#nav-trigger').toggleClass('open')
    });
  });

}


// end function toggleLayer()

    /**
 * Zum Seitenanfang springen
 */


function wg_moveUp() {

  $("#toplink").click(function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $("body").offset().top
    }, 1000);
  });

} // end function moveUp() 


document.getElementById('logo-large').style.display = 'none';

function checkScrollPosition() {
  var sc = $(window).scrollTop();
  //alert(sc);
  if (sc > 50) { return false; }
  else { return true; }
}



$(document).ready(function () {

  if (checkScrollPosition()) { $("#logo-large").show(); }
  // else {$("#logo").show(); }

  if (!$("body").hasClass("nav-on-sm-devices")) {
    $(window).scroll(function () {
      if (checkScrollPosition()) {
        $("#mainnav").removeClass('mainnav-pos');
        $(".page-header").removeClass('header-scroll');
        $("#logo").removeClass('scroll');
        $("#logo-large").fadeIn(500);
        $(".siegel").fadeOut(200);
      } else {
        $("#mainnav").addClass('mainnav-pos');
        $(".page-header").addClass('header-scroll');
        $("#logo").addClass('scroll');
        $("#logo-large").fadeOut(200);
        $(".siegel").fadeIn(500);
      }
    });
  }

});

